<template>
  <div class="main-conent main-conent-screen main-conent-bgFFF main-conent-borderradius">
    <div class="screen-header">
        <el-row>
            <el-col :span="8">
                <el-button type="primary" icon="el-icon-plus" @click="handleAdd">新增</el-button>
            </el-col>
            <el-col :span="16" class="text-right">
                <el-input
                    class="margin-l10"
                    style="width:200px;"
                    placeholder="请输入搜索内容"
                    clearable
                    v-model="queryParams.search">
                    <i slot="suffix" class="el-input__icon el-icon-search cursor-pointer" @click="getList()"></i>
                </el-input>
            </el-col>
        </el-row>              
    </div>

    <screen-table
        class="screen-conent"
        table-class="custom-table"
        header-row-class-name="custom-table-header"
        :data="tableData">
            <el-table-column
                fixed
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="name"
                label="脚本内容">
            </el-table-column>
            <el-table-column
                prop="statusDesc"
                label="状态">
            </el-table-column>
            <el-table-column
                prop="createUserId"
                label="创建人">
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间">
                <template slot-scope='scope'>
                    <span>{{ parseTime(scope.row.createTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="180">
                <template slot-scope="scope">
                    <el-button @click="handleDetail(scope.row)" type="text" :disabled="scriptStatusLimit.lastIndexOf(scope.row.status) >= 0">配置</el-button>
                    <el-button @click="handleMashup(scope.row)" type="text" :disabled="scope.row.status !== 5 && scope.row.status !== 6 && scope.row.status !== 8">开始混剪</el-button>
                </template>
            </el-table-column>
    </screen-table>

    <div class="screen-footer text-right">
        <el-pagination
            background
            layout="sizes, prev, pager, next, total"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            @current-change="handlePageNo"
            @size-change="handlePageSize"
        >
        </el-pagination>
    </div>
    
    <!-- 脚本添加 -->
    <el-dialog title='脚本添加' :visible.sync='open' width='500px' :before-close='cancel'>
        <el-form ref='form' :model='form' :rules='rules' label-width='130px'>
            <el-form-item label='脚本内容' prop='name'>
                <el-input v-model='form.name' placeholder='请输入脚本内容' />
            </el-form-item>
            <el-form-item label='脚本类型' prop='type'>
              <el-select v-model="form.type" placeholder="请选择脚本类型">
                <el-option
                  v-for="item in scriptType"
                  :key="item.key"
                  :label="item.name"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='素材类型' prop='materialType'>
              <el-select v-model="form.materialType" placeholder="请选择素材类型">
                <el-option
                  v-for="item in scriptMaterialType"
                  :key="item.key"
                  :label="item.name"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
        </el-form>
        <div slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='submitForm'>确 定</el-button>
        <el-button @click='cancel'>取 消</el-button>
        </div>
    </el-dialog>
    
    <!-- 配置 -->
    <el-drawer
      :visible.sync="detailOpen"
      direction="rtl"
      size="80%"
      :wrapperClosable="false"
      :before-close="cancel">
      <div style="margin-left: 10px;">
        <el-card>
          <el-descriptions title="脚本信息">
            <el-descriptions-item label="脚本内容">{{ scriptInfo.name }}</el-descriptions-item>
            <el-descriptions-item label="脚本类型">{{ scriptInfo.typeDesc }}</el-descriptions-item>
            <el-descriptions-item label="素材类型">{{ scriptInfo.materialTypeDesc }}</el-descriptions-item>
            <el-descriptions-item label="比例">{{ scriptInfo.width ? (scriptInfo.width + '*' + scriptInfo.height) : '' }}</el-descriptions-item>
            <el-descriptions-item label="状态">{{ scriptInfo.statusDesc }}</el-descriptions-item>
            <el-descriptions-item label="负责人ID">{{ scriptInfo.createUserId }}</el-descriptions-item>
            <el-descriptions-item label="创建时间">{{ parseTime(scriptInfo.createTime) }}</el-descriptions-item>
          </el-descriptions>
        </el-card>
        <div>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>场景配置</span>
            <el-tooltip class="item" effect="dark" content="用于生成场景，保存后不能修改" placement="top-start" style="margin-left: 10px;">
              <el-button @click="handleGenerateShotGroup" type="primary" :disabled="newShotGroupList && newShotGroupList.length > 0 && newShotGroupList[0].id !== null">随机场景</el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="用于生成台词" placement="top-start" style="margin-left: 10px;">
              <el-button @click="() => handleGenerateLine()" type="primary" v-if="newShotGroupList && newShotGroupList.length > 0">生成台词</el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="保存场景信息后，再做其他操作" placement="top-start" style="margin-left: 10px;">
              <el-button @click="handleEditShotGroup" v-if="newShotOpen" style="margin-left: 10px;" type="warning">保存场景</el-button>
            </el-tooltip>
          </div>
          <div>
            <el-collapse v-model="activeNames"  @change="handleShotClick" accordion>
              <el-collapse-item v-for="(item,index) in newShotGroupList" :key="index" :title="item.script" :name="index">
                <el-card class="box-card">
                  <div slot="header" class="clearfix">
                    <span>台词</span>
                    <el-button style="margin-left: 10px;" type="text" @click="() => handleGenerateLine(item.sort)" v-if="item.line">生成台词</el-button>
                    <el-button style="margin-left: 10px;" type="text" @click="handleEditShotGroup" v-if="newShotOpen">保存台词</el-button>
                    <el-button style="margin-left: 10px;" type="text" v-if="item.line" @click="() => handleOpenAudio(item)">语音合成</el-button>
                    <el-button style="margin-left: 10px;" type="text" v-if="item.line" @click="() => handleDownload(item['audioInfoDto']['path'])">语音试听</el-button>
                  </div>
                  <div>
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4}"
                      placeholder="暂无台词，请生成或者输入"
                      show-word-limit
                      clearable
                      v-model="item.line">
                    </el-input>
                  </div>
                </el-card>
                <el-card class="box-card">
                  <div slot="header" class="clearfix">
                    <span>素材（<span style="color: red;">建议时长：{{ item.mp3Size }}s</span>）</span>
                    <el-upload
                      class="upload-demo"
                      :ref="'upload_' + item.id"
                      :action="upload.url"
                      :auto-upload="false"
                      :headers="upload.headers"
                      :on-success="() => handleSuccess(item, 'upload_' + item.id)"
                      :multiple="true"
                      :limit="10"
                      :accept="scriptInfo.materialType === 0 ? 'mp4' : 'png'"
                      :data="{'scriptId': item.scriptId, 'shotId': item.id, 'position': 0}"
                      :file-list="upload.fileList">
                      <el-button slot="trigger" size="small" type="text">选取文件</el-button>
                      <el-tooltip class="item" effect="dark" content="生成台词后才能上传素材" placement="top-start" style="margin-left: 10px;">
                        <el-button type="text" :disabled="!item.id" @click="() => handleUpload('upload_' + item.id)">确定上传</el-button>
                      </el-tooltip>
                      
                      <div slot="tip" class="el-upload__tip">只能上传png/mp4文件，大小不超过50m，单词最大上传10个文件</div>
                    </el-upload>
                  </div>
                  <div>
                    <screen-table
                      class="screen-conent"
                      table-class="custom-table"
                      header-row-class-name="custom-table-header"
                      :data="material['list'] ? material['list'] : []">
                          <el-table-column
                              prop="id"
                              label="主键">
                          </el-table-column>
                          <el-table-column
                              prop="path"
                              label="素材地址">
                              <template slot-scope='scope'>
                                  <span v-if="scope.row.position === 0"><a>下载</a></span>
                                  <span v-else>
                                    <video v-if="scriptInfo.materialType === 0" width="100px">
                                      <source :src="scope.row.path" type="audio/wav">
                                      您的浏览器不支持播放该音频格式。
                                    </video>
                                    <img v-else :src="scope.row.path" width="100px"/>
                                  </span>
                              </template>
                          </el-table-column>
                          <el-table-column
                              prop="createTime"
                              label="创建时间">
                              <template slot-scope='scope'>
                                  <span>{{ parseTime(scope.row.createTime) }}</span>
                              </template>
                          </el-table-column>
                          <el-table-column
                              fixed="right"
                              label="操作"
                              width="100">
                              <template>
                                  <el-button type="text" >删除</el-button>
                              </template>
                          </el-table-column>
                  </screen-table>

                  <div class="screen-footer text-right">
                      <el-pagination
                          background
                          layout="sizes, prev, pager, next, total"
                          :page-sizes="[10, 20, 50, 100]"
                          :total="material['total']"
                          @current-change="(p) => handleMaterialPageNo(p, item)"
                          @size-change="(s) => handleMaterialPageSize(s, item)"
                      >
                      </el-pagination>
                  </div>
                  </div>
                </el-card>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-card>
        </div>
      </div>
    </el-drawer>

    <!-- 语音合成 -->
    <el-dialog title='语音合成' :visible.sync='audioOpen' width='70%' :before-close='handleAudioCencel'>
        <el-form ref='audioForm' :model='audioForm' :rules='rules' label-width='130px'>
            <el-form-item label='模型' prop='model'>
                <el-select v-model="audioForm.model" filterable placeholder="请选择模型" @change="handleChangeModel">
                  <el-option
                    v-for="item in audioList"
                    :key="item.model"
                    :label="item.name + '(' + item.model + ')'"
                    :value="item.model">
                  </el-option>
                </el-select>
                <el-button style="margin-left: 10px;" type='primary' @click="() => handleDownload(audioForm['path'])" :disabled="!audioForm['path']">试听</el-button>
            </el-form-item>
            <el-form-item label='采样率' prop='sampleRate'>
                <el-input-number v-model='audioForm.sampleRate' placeholder='请输入采样率' />
            </el-form-item>
            <el-form-item label='音量' prop='volume'>
                <el-input-number v-model='audioForm.volume' placeholder='请输入音量' :min="-500" :max="500"/>
            </el-form-item>
            <el-form-item label='语速' prop='rate'>
                <el-input-number v-model='audioForm.rate' placeholder='请输入语速' :min="-500" :max="500"/>
            </el-form-item>
            <el-form-item label='语调' prop='pitch'>
                <el-input-number v-model='audioForm.pitch' placeholder='请输入语调' :min="-500" :max="500"/>
            </el-form-item>
        </el-form>
        <div slot='footer' class='dialog-footer'>
        <el-button type='primary' @click="() => handleGenerateAudio([this.shotGroupOne['id']])">生成</el-button>
        <el-button type='primary' @click='handleGenerateAudioAll'>全部生成</el-button>
        <el-button @click='handleAudioCencel'>取 消</el-button>
        </div>
    </el-dialog>
    
  </div>
</template>
<script>

import ScreenTable from '@/components/ScreenTable';
import { scriptPage, saveOrUpdateByScript, shotGroupPage, saveOrUpdateByShotGroup,
  generateShotGroup, generateLine, materialPage, audioTmplConfigAll, batchGenerateMp3,
  generateMashupTask } from '@/service/mashup';
import store from '@/store';
import { download } from '@/service/fileInfo';

export default {
  components: {
      ScreenTable
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        search: undefined
      },
      total: 0,
      tableData: [],
      // 是否显示弹出层
      open: false,
      // 表单参数
      form: {
        type: 1,
        materialType: 0
      },
      // 表单校验
      rules: {
        name: [
          { required: true, message: '脚本内容不能为空', trigger: 'blur' }
        ]
      },
      scriptType: [
        {key: 0, name: "通用短视频"},
        {key: 1, name: "商品售卖短视频"}
      ],
      scriptMaterialType: [
        {key: 0, name: "视频(mp4)"},
        {key: 1, name: "图片(png)"}
      ],
      detailOpen: false,
      scriptInfo: {},
      shotGroupList: [],
      newShotGroupList: [],
      newShotOpen: false,
      activeNames: [],
      upload: {
        url: process.env.VUE_APP_APIHOST + '/mashup/batch_upload_material',
        headers: {
          Authentication: store.getters.token
        },
        fileList: []
      },
      material: {},
      audioOpen: false,
      scriptStatusLimit: [1, 4, 7],
      audioList: [],
      audioForm: {}
    };
  },
  created() {
    this.getList();
    this.handleQueryAudioCOnfig();
  },
  methods: {
    getList() {
      scriptPage(this.queryParams).then(response => {
        this.tableData = response['data']['records'];
        this.total = response['data']['total'];
      });
    },
    handlePageNo(pageNo) {
        this.queryParams = {
            ...this.queryParams,
            pageNo
        };
        this.getList();
    },
    handlePageSize(pageSize) {
        this.queryParams = {
            ...this.queryParams,
            pageSize
        };
        this.getList();
    },
    handleAdd() {
        this.open = true;
        this.form = {
          type: 1,
          materialType: 0
        };
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.resetForm('form');
      this.detailOpen = false;
      this.newShotOpen = false;
      this.scriptInfo = {};
      this.shotGroupList = [];
      this.newShotGroupList = [];
      this.activeNames = [];
      this.material = {};
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          saveOrUpdateByScript(this.form).then(response => {
            if (response.code === 0) {
              this.cancel();
              this.getList();
            } else {
              this.$message({
                  type: 'info',
                  message: '异常'
              });  
            }
          });
        }
      });
    },
    tableDel(){
          this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
          }).then(() => {
              // deleteBatch({ids: [row.id]}).then(response => {
              //   if (response.code === 0) {
              //     this.$message({
              //         type: 'success',
              //         message: '删除成功!'
              //     });
              //     this.getList();
              //   } else {
              //     this.$message({
              //         type: 'info',
              //         message: '异常'
              //     });  
              //   }
              // });
          }).catch(() => {
              this.$message({
                  type: 'info',
                  message: '已取消删除'
              });          
          });
    },
    handleDetail(row){
      this.getShotGroupList(row);
    },
    getShotGroupList(row) {
      shotGroupPage({scriptId: row.id, pageSize: 20}).then(response => {
        this.shotGroupList = response['data']['records'];
        this.newShotGroupList = this.shotGroupList;
        this.scriptInfo = row;
        this.detailOpen = true;
        if(this.newShotGroupList && this.newShotGroupList.length > 0){
          this.activeNames = [0];
          this.handleMaterialPage(this.newShotGroupList[0]);
        }
      });
    },
    handleGenerateShotGroup(){
      generateShotGroup(this.scriptInfo).then(response => {
        this.newShotGroupList = response['data'];
        if(this.newShotGroupList && this.newShotGroupList.length > 0){
          this.activeNames = [0];
          this.handleMaterialPage(this.newShotGroupList[0]);
        }
        this.newShotOpen = true;
      });
    },
    handleEditShotGroup(){
      let param = {
        shotGroupList: this.newShotGroupList,
        scriptId: this.scriptInfo.id
      };
      saveOrUpdateByShotGroup(param).then(response => {
        if (response.code === 0) {
          this.newShotOpen = false;
          this.getShotGroupList(this.scriptInfo);
          this.$message({
              type: 'success',
              message: '保存成功!'
          });
        } else {
          this.$message({
              type: 'info',
              message: '异常'
          });  
        }
      });
    },
    handleGenerateLine(generateIndex){
      let param = {
        scriptId: this.scriptInfo.id,
        shotList: this.newShotGroupList,
        generateIndex
      };
      generateLine(param).then(response => {
        this.newShotGroupList = response['data'];
        if(this.newShotGroupList && this.newShotGroupList.length > 0){
          this.activeNames = [0];
        }
        this.newShotOpen = true;
      });
    },
    handleUpload(refName){
      this.$refs[refName][0].submit();
    },
    handleMaterialPage(item, pageNo = 1, pageSize = 20){
      let param = {
        shotId:item.id,
        pageNo,
        pageSize
      };
      materialPage(param).then(response => {
        let material = {
          list: response['data']['records'],
          total: response['data']['total'],
          pageNo,
          pageSize
        };
        this.material = material;
      });
    },
    handleMaterialPageNo(pageNo, item) {
        this.handleMaterialPage(item, pageNo);
    },
    handleMaterialPageSize(pageSize, item) {
        this.handleMaterialPage(item, 1, pageSize);
    },
    handleShotClick(val){
      this.material = [];
      if(val || val === 0){
        this.handleMaterialPage(this.newShotGroupList[val]);
      }
    },
    handleSuccess(item, refName){
      this.$refs[refName][0].clearFiles();
      this.handleMaterialPage(item);
    },
    handleMashup(row){
      generateMashupTask({id: row.id}).then(response => {
        if (response.code === 0) {
          this.getList();
        } else {
          this.$message({
              type: 'info',
              message: '异常'
          });  
        }
      });

    },
    handleOpenAudio(row){
      this.shotGroupOne = row;
      this.audioForm = {
        ...row['audioInfoDto'],
        path: ""
      };
      this.audioOpen = true;
    },
    handleQueryAudioCOnfig(){
      audioTmplConfigAll().then(response => {
        this.audioList = response['data'];
      });
    },
    handleChangeModel(model){
      let audioConfigList = this.audioList.filter((item) => {
        return item.model === model;
      });
      let audioConfig = audioConfigList[0];
      this.audioForm = {
        ...this.audioForm,
        model: audioConfig['model'],
        sampleRate: audioConfig['sampleRate'],
        volume: audioConfig['volume'],
        rate: audioConfig['rate'],
        pitch: audioConfig['pitch'],
        path: audioConfig['path']
      };
      
    },
    handleAudioCencel(){
      this.audioOpen = false;
      this.audioForm = {};
      this.shotGroupOne = {};
      this.resetForm('audioForm');
    },
    handleGenerateAudio(ids){
      this.$refs['audioForm'].validate(valid => {
        if (valid) {
          batchGenerateMp3({ids, audioInfoDto: this.audioForm, scriptId: this.scriptInfo['id']}).then(response => {
            if (response.code === 0) {
              this.getShotGroupList(this.scriptInfo);
              this.handleAudioCencel();
            } else {
              this.$message({
                  type: 'info',
                  message: '异常'
              });  
            }
          });
        }
      });
    },
    handleGenerateAudioAll(){
      let ids = this.newShotGroupList.map((item) => item['id']);
      this.handleGenerateAudio(ids);
    },
    handleDownload(path){
      download({path}).then(response => {
        let split = response.headers['content-disposition'].split('=');
        let name = split[split.length - 1];
        let url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.style.display = "none";
        a.click();
        document.body.removeChild(a);
      });
    }
  }
};
</script>